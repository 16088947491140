 /*  Fonts
 *******************************/

@font-face {
     font-family: 'Montserrat-Regular';
     font-style: normal;
     font-weight: normal;
     src: local('Myriad Pro Regular'), url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
     font-family: 'Montserrat-Medium';
     font-style: normal;
     font-weight: normal;
     src: local('Myriad Pro Regular'), url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
     font-family: 'Montserrat-Bold';
     font-style: normal;
     font-weight: normal;
     src: local('Myriad Pro Regular'), url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
}



 /*  Overwrite
 *******************************/
.editorContentFields > div > span > .MuiSwitch-switchBase {
	width:auto !important;
}

 button {
   color:#1c3254 !important;
 }

.MuiDialogActions-root {
   justify-content: space-around;
}

.Mui-checked {
    color: #1c3254 !important;
}

.MuiOutlinedInput-root {
	height:40px;
}

.react-tabs__tab-list {
	.react-tabs__tab {
		bottom: 0px !important;
	}


	text-align: center;
	margin-bottom:0 !important;
	border-bottom: 2px solid #1c3254 !important;
	.react-tabs__tab--selected {
	    border: 2px solid #1c3254 !important;
        color: #1c3254 !important;
        margin-bottom: -10px !important;
        border-bottom: 2px solid #fff !important;
        font-weight: 600;
	}
}

.Mui-hovered {
	background-color:#fff !important;
}

.MuiDataGrid-columnHeaderTitleContainer div {
    font-weight: 600 !important;
}
.MuiDataGrid-cell:focus-within {
    outline: solid rgba(25, 118, 210, 0.5) 0px !important;
}
.MuiDataGrid-row {
	cursor:pointer;
}

.MuiFormLabel-root {
     top: -7px !important;
}

.MuiInputLabel-sizeSmall {
     top: 0px !important;
}



/*  Checked
*******************************/
.unChecked {

  svg,
  span {
    background: #fff;
    color: #aaa;
  }
}




/*  Params
*******************************/

$space : 6px;
$fontSize: 14px;


/*  Multi Select
*******************************/

.rmsc {
	padding: 8px;
	font-size:16px;
	color:rgba(0,0,0,.87);
}

.dropdown-content {
	position:absolute !important;
	bottom:50px !important;

	top:auto !important;
}

.dropdown-heading-value .gray {
	color:rgba(0,0,0,.87) !important;
}


/*  Default
*******************************/

body {
	color:#1c3254;
	background:#fff;
}

.App {
	max-width:100%;
	width:100%;
	margin:auto;
	line-height:1.56;
	font-size: $fontSize;
	font-family: 'Montserrat-Regular';

	a {
		cursor:pointer;
		color:#1c3254;
		text-decoration:none;
	}


    a:hover {
        opacity:.8;
    }
}


.wrapper-content {
    max-width: 1400px;
    min-width: 300px;
    margin: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding:0;
	margin:0;
	font-family: 'Montserrat-Medium';
	font-weight: 400;
}

h1 { font-size: calc($fontSize * 1.8) }
h2 { font-size: calc($fontSize * 1.6) }
h3 { font-size: calc($fontSize * 1.4)  }
h4 { font-size: calc($fontSize * 1.2) }
h5 { font-size: calc($fontSize * 1); font-weight: 600;}
h6 { font-size: calc($fontSize  * 0.8) }

.buttonBar {
	display: flex;
	flex-direction: row-reverse;
}

.button {
    margin-top: 12px;
    padding: 6px 18px;
    background: #FFFFFF;
    font-family: "Montserrat-Medium";
    font-weight: 400;
    border-radius: 48px;
    border: 2px solid #1c3254;
    display: inline-block;
    margin-left: 12px;
    color: #1c3254;
    align-items: center;
    cursor:pointer;
    text-decoration: none;
	svg{
	    margin-right:6px;
	}
}

.buttonFull {
	margin-top: 12px;
	padding: 12px 18px;
	background: #1c3254;
	border-radius: 48px;
	border: 2px solid #1c3254;
	display: inline-block;
	margin-left: 12px;
    cursor:pointer;
	color: #ffffff;
	align-items: center;
	text-decoration: none;

	svg{
		margin-right:6px;
	}
}

.subButton {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;

  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #eee;
    border: 1px solid #1c3254;
  }
}

 /* Tasklist
  *****************************/
 .taskList {
   padding:10px;
   display:flex;
   justify-content: space-between;

   div:first-child {
     width:100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;

   }
   div:last-child {
     width:300px;
     text-align: right;
   }
 }


 /* dialog-description
  *****************************/
  .dialogDescription {

    .MuiFormControl-root {
        width:100%;
        label {
            background:#fff;
            padding: 0 calc($space * 2);

        }
    }



    > div {
        width:100%;
    }

	> div:last-child {
		margin-top: calc($space * 4);

	}

	.dialogForm > div {
		margin-top: calc($space * 4);
	}

  }

  thead th{
		font-weight: 600 !important;
		svg {
			opacity:0;
		}
  }

  thead th:hover svg{
  		opacity:.6;
    }

  thead th svg:hover{
	    opacity:1;
  }

 /* Splashscreen component
  *****************************/
  .splashScreen {
    height: 71vh ;
    display:flex;
    max-width: 700px;
    margin: auto;
    align-items: center;
    padding: calc($space * 2) calc($space * 4);
    flex-direction: column;

    > div {
        align-self: center;
        text-align: center;
    }

    h3 {
        margin-top: calc($space * 4);
        margin-bottom: 0;
    }

    a {
        text-decoration: underline;
        cursor:pointer;
    }

    p {
        color: #1c3254;
        text-align:center;
    }

    img {
        width:40vw;
        max-width:250px;
    }


   svg {
        font-size: 16px !important;
    }

    .primaryActions {
        display:flex;
        flex-wrap: wrap;
          justify-content: center;

        a:last-child {
            margin-left:calc($space * 2);

        }
    }

    .splashScreenFields
    {
        width:100%;
        display:flex;
        flex-wrap:wrap;
        padding: calc($space * 2) 0;


        > div {
              margin-bottom: calc($space * 2);
          }

          [colorschema="red"] {

            fieldset {
                border-color: #BBC1C9;
            }

            .MuiInputAdornment-positionStart > p {
                font-size:24px;
            }

              legend,
              input,
              label,
               p{
                   color: #BBC1C9;
              }
          }

          [colorschema="green"] {

              fieldset {
                  border-color: #BBC1C9;
              }

              .MuiInputAdornment-positionStart > p {
                  font-size:24px;
              }

              input,
              legend,
              label,
               p{
                   color: #BBC1C9;
              }
          }


          [colorschema="black"] {

            fieldset {
                border-color: #1c3254;
            }

            .MuiInputAdornment-positionStart > p {
                font-size:24px;
            }

            input,
            legend,
            label,
             p{
                 color: #1c3254;
            }
        }

    }

    .highlighed {
        margin-top: calc($space * 2);
        padding: calc($space * 1) calc($space * 3);
        background:  #1c3254;
        border-radius: calc($space * 8);
        display:flex;
        color: #FFFFFF;
        align-items:center;
        text-decoration: none;
        svg {
                margin-right: calc($space * 2 );
            }
    }

    .secondary {
          margin-top: calc($space * 2);
          padding: calc($space * 1) calc($space * 3);
          background:  #FFFFFF;
          border-radius: calc($space * 8);
          border: 2px solid #1c3254;
          display:flex;
          margin-left: 12px;
          color: #1c3254;
          align-items:center;
          text-decoration: none;
          svg {
                margin-right: calc($space * 2 );
            }
      }
  }

  .userPanel {
	display:block !important;
  }


 /* Is24 component
  *****************************/
  .is24ExposeId {
    .actions {
        display:flex;
        justify-content: space-between;;
        padding: 24px 0;
        column-gap: 24px;

      button,
      .MuiFormControl-root  {
        width:100%;
      }

      button {
        border: 2px solid #1c3254 !important;
        padding: 18px 24px !important;
        border-radius: 4px !important;
        height: 35px !important;
        font-size: 12px !important;
        color: #1c3254 !important;
        margin-left: 10px !important;
      }
    }



    .info {
      border: 2px solid #eee;
      background: #f0f0f0;
      border-radius: 4px !important;
      padding: 4px 8px !important;
    }

    small {
      font-size:12px;
    }
  }

 /* Notes component
  *****************************/
  .notes {
	    display: flex;
        flex-direction: column;

        .button {
            align-self: flex-end;
        }
  }


 /* Filter
  *****************************/
  .filter {
	  padding: 24px;
      width:100%;
      text-align: right;

      .MuiBadge-badge {
        margin-right: -20px;
        background: #10ffa9;
        color: #000;
      }
  }

  .filterButton {
    border: 2px solid #1c3254 !important;
    padding: 12px 24px !important;
    border-radius: 50px !important;
    height: 35px !important;
    font-size: 12px !important;
    color: #1c3254 !important;
    margin-left: 10px !important;
  }

  .filterFieldBlock {
    display:flex;
    column-gap: 24px;
    padding:5px 0;

    > div {
      width:100%;
    }

    .MuiFormControl-root {
      padding: 7px 0;
      width:100%;
    }

    .MuiFormLabel-root {
      width:100%;
      top: 1px!important;
    }
  }

 /* Authenticator component
  *****************************/

 [data-amplify-authenticator] [data-amplify-router] {
   border:0 !important;
   box-shadow: 0 0 30px 0 rgba(0,0,0,.1)!important;
   border-radius: 8px!important;
 }

 [data-amplify-authenticator] {
   display: grid;
   height: 100vh;
   background:#fff;


   >:before {
     content:"";
     display:block;

     height:90px;
     background: url('./assets/image/logo.png');
     background-position: center center;
     background-repeat: no-repeat;
     background-size: 70% auto;
     border-radius: calc($space * 2);
     margin-bottom:calc($space * 10);

   }

   .amplify-field-group__outer-end > button,
   .amplify-input {

     border-color:#ccc;
   }

   .amplify-button--link {
     color: #1c3254;
     background:#fff;
   }

   .amplify-flex {
     margin-bottom: 8px;
   }

   .auth-checkbox  {
     display:flex;
   }
   .auth-checkbox a {
     color:#000;
     text-decoration: none;
     font-weight: 600;
   }

   .amplify-tabs-item {
     color: #1c3254;
   }

   .amplify-tabs-item {
     background: #f0f0f0 !important;
     border-top: 2px solid #f0f0f0 !important;
   }

   .amplify-tabs-item[data-state=active] {
     color: #06bd86;
     background: #fff !important;
     border-top: 2px solid #fff !important;
   }

   .amplify-button--primary {
     background: #06bd86;
     color: #ffffff;
     font-weight:400;
     padding: calc($space * 2) calc($space * 3);
     border-radius:50px;
   }
 }




 /* Navigation
  *****************************/

.navigation {



	display:flex;
	align-items:center;
	justify-content: space-between;
	padding: calc($space * 4);

	#logo img{
		height:40px;
		border-radius: $space;
		border-bottom-right-radius:0;
	}
	#back,
	#logo {
		display:flex;
		align-items:center;
		line-height:1;
			width:calc(100% - 140px );
		img,
		svg {
			font-size: calc($fontSize * 1.8);
			margin-right: calc($space * 2);
		}

		h2{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.56;
		}
	}

	#controlls {
		width: 170px;
		text-align: right;

		.userType {
	        color: #fff;
            padding: 2px 5px;
            background: orange;
            margin-bottom: -6px;
            line-height: 2em;
            font-size: 33.6px;
            border-radius: 50%;
		}

		a {
			color:#1c3254;
			padding: calc($space * 2);
			font-size: calc($fontSize * 1.8);
		}

	}
}


 /* Pages
  *****************************/

.mapboxgl-popup-content {
    box-shadow: 0 1px 40px rgb(0 0 0 / 25%) !Important;
    min-width:250px !Important;
}

.mapCard {
	.button {
		margin:auto;
		display:block;
		text-align:center;
		padding:5px 10px;
		background:#1c3254;
		color:#fff;
		border-radius:50px;
	}

	.list {
		margin: 20px 0;
		div{
			display:flex;
			justify-content:space-between;

			p {
				padding:0;
				margin:5px 0 ;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}


			a {
				text-decoration:underline !important;

			}
		}
	}

}
/* page-help-overview */

	#page-help-overview {
		padding: calc($space * 4)  calc($space * 4) 0  calc($space * 4);

		.helpGird
		{

			margin: calc($space * 8) -1%;

			div{

				width:100%;
				margin:50px 1%;

				b {
					margin-bottom:calc($space * 2);
				    font-weight: bold;
                    padding: 10px;
                    background: #eee;
                    border-radius: 5px;
				}

				iframe {
					border: 10px solid #eee;
	                border-radius: 5px;
	                height: 400px !important;
	                width: 98% !important;
				}
			}
		}


	}


/* page-potentials-overview */

    #page-potential-overview {

       table {
            min-width:auto;

            a {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width:100%;
            }
       }

       .potentialList {

           padding: calc($space * 4)  calc($space * 4) 0  calc($space * 4);

           .potentialListElement {
                margin-bottom:  calc($space * 4);
                display:flex;
				justify-content:space-between;

				a {
					font-family: 'Montserrat-Medium';
				}
				small {
					font-family: 'Montserrat-Medium';
					color: #BBC1C9;
				}

				.MuiAvatar-root {
					margin-right: calc($space * 2);
					border-radius:$space !important;
				}
				div {
					text-align:left;
					align-self: center;
					min-width:100px;
				}
				div:first-child {
                    text-align:left;
                    min-width:auto;
                    display:flex;
                }
				.image {
					border:1px solid #BBC1C9;
					padding: calc($space * 3);
					margin-right:  calc($space * 2);
					border-radius: $space;

				}
           }
       }
    }




 /* page-project-documents */
    #page-project-documents {
        a {
            display: flex;
            flex-direction: column;

        }


      .uploaderGrid {
          display:flex;
          gap: 48px;
          justify-content: space-between;

          aside {
            margin:0 30px;
            padding:30px;
            border: 2px solid #ddd;
            width:100%;
            border-radius: 10px;

            ul {
              margin:20px 0;
              padding:0;

              li {
                font-size: 14px;
                list-style: none;
                margin-bottom: 10px;
                text-align: left;
                display:flex;
                justify-content: space-between;
              }
            }
          }


          .uploader {

            border: 2px dashed #ccc;
            border-radius: 10px;
            font-size: 16px;
            text-align: center;
            cursor:pointer;
            max-width: 300px;


            >div {
              padding: calc(30vh - 120px) 30px;
              height: 60vh;
            }



            svg {
              font-size: 60px;
            }
          }
        }

        .previewImage svg {
          font-size: 100px;
          width: auto;
          margin: 20px calc(50% - 50px);
          text-align: center;
        }


        .documentSplit {
            display:flex;
            flex-wrap:wrap;
            margin-bottom:  calc($space * 5);
        }

		.dokumentLinks,
        > p {
            padding:  calc($space * 5);
        }

		.document2 {
			max-width:620px;
		}


		iframe {
			width:100%;
			height:4000px;
			border:0;
		}

        .documentOverview {
            display:flex;
            flex-wrap:wrap;
			justify-content:space-evenly;


            .documentElement {
                width: 30%;
                border:2px solid #ffffff;
                margin-top: calc($space * 4);
                min-width:280px;
                box-shadow: 0 0 40px 0 rgba(0,0,0,.1);

				.name {
					padding: calc($space * 2);
                    maring:auto;
                    width:100%;
                    text-align:center;
                    font-weight: 600;

				}

				img {
					width: calc(100% - 48px);

					object-fit: cover;
                    margin:0 24px;
				}

				.previewImage {
					.logo {
						object-fit: contain;
					}
				}


            }
        }

		.documentBlock {
			display:flex;
			margin: 60px 24px;
			column-gap: 60px;
			justify-content: space-between;
			line-height: 1.56;

			ol {
				margin: 0;
				padding: 14px;

				li {
					line-height: 2;
				}
			}

			.documentCard > div{
				min-width: 300px;
				box-shadow: 0 0 60px 0 rgba(0,0,0,.1);
				padding:24px;

				a {
					display:inline-block;
				}
			}
		}


    }






 /* page-project-overview */

    #page-project-overview {

        .tableRowTitle td {
            font-size: 0.875rem !important;
        }

	   table {
	        min-sidth:auto;

	        tr {
	              white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width:100%;
	        }
	   }

        .tableRowTitle a {
            display: flex;
            align-items: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width:300px;
            div {
                 margin-right: calc($space * 2);
            }
        }

        .plotList {
            display:grid;
            grid-template-columns: 24%  24% 24% 24%;
			column-gap: calc($space * 4);
			row-gap: calc($space * 4);
			margin:calc($space * 4);

	        .plotListEntity {
				min-with:250px;
				padding: calc($space * 4);
				border: 1px solid #d7d7d7;
				border-radius: 10px;

				.title {
					display:flex;
					justify-content: space-between;
					font-size:1.2em;
					margin-bottom: calc($space * 4);

					a {
						display:block;
						span,
						b {
							width:100%;
							display:block;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}

					.delete {

						font-size:20px;
					}
				}

				.price {
					justify-content:space-between;
                    display:flex;
                    flex-direction:column;
                    margin-top: calc($space * 4);
					align-items: end;
					span:first-child {
					    color: #ABABAB;
					    font-weight:100;
					    font-size:20px;
					    color:#000000;
					}
				}


				.details {
					div {
						justify-content:space-between;
						display:flex;
						margin-top: calc($space * 2);
						span:first-child {
							color: #ABABAB;
							font-weight:600;
						}
						span:last-child {
							color: #000000;
							font-weight:600;
						}
					}
				}
	        }
        }
    }





 /* page-user-profile */
 #page-user-profile{
	padding:calc($space * 4);
	    ul {
	        margin:0;
	        margin-top:calc($space * 4) ;
	        padding:0;

	        li {
	            list-style:none;
	            border: 1px solid #BBC1C9;
	            text-align:center;
	            padding:calc($space * 1);
	            margin-bottom: $space;

	        }
	    }


 }



 /* page-user-houseDatabase */
#page-user-houseDatabase {
	padding:calc($space * 4);
}



 /* page-user-integration */
 #page-user-integration{
	padding:calc($space * 4);
	    .integrationManual {

	        padding-left:calc($space * 9) ;
	        padding:0;
	        margin:auto;
			margin-top:calc($space * 9) ;

	        li {
	            list-style:none;
	            text-align: justify;
	            line-height:2rem;
	            margin-bottom: calc($space * 4);

	            .code {
	                background:#1c3254;
	                color:#fff;
	                display:block;
	                padding: calc($space * 4);
	                text-align:center;
	                border-radius: $space;
	            }
	            .code1 {
	                background:#eeeeee;
	                color:#1c3254;
	                display:block;
	                padding: calc($space * 4);
	                text-align:left;
	                border-radius: $space;
	            }

		        span {
		            list-style:none;
		            text-align:center;
		            margin-right: $space ;
					display:inline-block;
					font-size:1.2rem;
					width: 20px;
					height: 20px;
					border-radius:50%;
		        }
	        }
	    }


 }


 /* page-user-setting */

 #page-user-setting{
	> div {
		margin: calc($space * 4);
		> div {
            margin-bottom: calc($space * 4);
        }
	}

 }

 /* page-potentials-map */

	#page-potentials-map {
		border-top:1px solid #eee;
	    .map-container {
	        width:calc(100vw - 1px);
	        height: calc(100vh - 110px);
	    }
	}



 /* page-project-editor */

	#page-project-editor {
		.swiper {
		     padding: calc($space * 4);

			.swiper-pagination-bullet-active {
				width:20px;
				border-radius:4px;
				background: #1c3254;
			}
	    }

	    .map-container {
	        height:500px;
			margin:auto;
	        width:100%;

	    }

        .addressBlock.error .MuiFormHelperText-root {
          color: red !important;
        }

	    .addressBlock {


	        > div {
	            grid-template-columns: 1fr 1fr;
	            gap: 1rem;
                display:grid;
                margin-top: 1rem;
	        }

			mapbox-address-autofill {
				grid-column-start: 1;
                grid-column-end: 3;
				div,
                input {
                    width:100%;
                }
			}
	    }

	    .swiper-button-prev, .swiper-rtl .swiper-button-next {
           left: 32px;
           bottom: 10px;
           top: auto;
           position: fixed;
           padding: calc($space * 2);
           width: 100px;
           color: #1c3254;
           border-radius: 80px;
           content: "Weiter";
           display: block;
           text-align:center;
        }

        .swiper-button-prev:after, .swiper-rtl .swiper-button-prev:after {
                    content: "Zurück";
                    display: block;
                    color: #1c3254;
                    font-family: 'Montserrat-Medium';
                    font-size: 1rem;
                    z-index:100;
                }

		.swiper-button-next, .swiper-rtl .swiper-button-prev {
            right: 32px;
            bottom: 10px;
            top: auto;
            position: fixed;
            padding: calc(( $space * 2 ) + 2px );
            width: auto;
            background: #1c3254;
            border-radius: 80px;
            content: "Weiter";
            display: block;
            text-align:center;
        }

	    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
            content: "Weiter";
            display: block;
            color: #ffffff;
            font-family: 'Montserrat-Medium';
            font-size: 1rem;
            z-index:100;
        }

        .swiper-button-next.back {

            cursor: pointer !important;
            pointer-events: auto !important;
        }

		.swiper-button-next.back:after {
            content: "Abschließen" !important;
        }
		.swiper-button-next.swiper-button-disabled.back {
            opacity: 1 ;
        }

	    .swiper-slide {
			width:400px;
			padding: calc($space * 4);

	        box-shadow: 0 0 40px 0 rgba(0,0,0,.1);

	        .editorContent {
	            text-align:center;

				.calculatedInput {
					display: inline-block;
					width:100%;
					border-radius:5px;
                    background: #f0f0f0;
                    border: 1px dashed #aaa !important;

					> span {
						margin-top:- calc($space * 1.8);
						margin-left: 9px ;
						padding:0  calc($space);
						display:inline-block;
						position:absolute;
						font-family: "Montserrat-Regular";
						background:#fff;
						border-radius:5px;
					}
					>div {
						display:flex;
						padding: 9.2px 3px 9.8px 6px;
						justify-content:space-between;
						font-size: $fontSize;
						font-family: "Montserrat-Regular";
						span {
							padding-right: calc($space * 1.8);
						}
					}
				}


				img {
					width:40%;
					padding-bottom: calc($space * 4);
					max-width: 240px;
				}

				p {
					max-width: 500px;
					margin: calc($space * 2) auto;
				}

	            .editorContentFields {
	                margin: calc($space * 8) auto 0 auto;
	                text-align:left;
	                max-width:600px;

					.MuiSelect-select {
						padding:5px 13.5px;
						font-family: "Montserrat-Regular";
                        color: #1c3254;
						font-size: $fontSize;
					}

					> div.switcher {
						display:flex;
						align-items: center;
					}

					> div {
						margin-bottom: calc($space * 3);
					}

					input {
					    font-size: $fontSize;
					}

					fieldset {

                        border-color: #1c3254;
                    }

                    legend,
                    input,
                    label,
                     p {
                          color: #1c3254;
                          font-family: "Montserrat-Regular";
                    }


					[colorschema="red"] {

                        fieldset {
                            border-color: red;
                        }
                        legend,
                        input,
                        label,
                         p {
                              color: red;
                              font-family: "Montserrat-Regular";
                        }
                    }

                    [colorschema="green"] {

                        fieldset {
                            border-color: green;
                        }

                        input,
                        legend,
                        label,
                        p {
                             color: darken(green,10);
                             font-family: "Montserrat-Regular";
                        }
                    }


                    p {
                        font-size:$fontSize;
						font-family: "Montserrat-Regular";
                    }
	            }
	        }
	    }
	    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
            bottom: 0px;
            left: 0;
            height: 50px;
            width: 100%;
            position: fixed;
            padding-top: $space;
             z-index:1;
        }
	}

 /* page-drafts-map */

   #page-drafts-map {
			.data-container {
				.regionalPremiumPercent,
				.regionalPremiumPercentMin,
				.regionalPremiumPercentMax,
				.incidentalBuildingCosts {
					display:inline-block;

					p {
						    position: absolute;
                            right: 12px;
                            top: 12px;
                            font-size: 12px;
					}
				}


				p {
					margin-bottom: calc($space * 4);
				}


				h3 {
					margin: calc($space * 8) 0 0 0;
					display:flex;
					align-items: center;
					justify-content:space-between;

                    i,
					a {
						font-size:12px;
						padding: 6px 18px;
						margin-top:0;
					}
				}

				div:first-child h3 {
                    margin: 0;
                }

	            input {
	                font-size: 12px;
                    font-family: "Montserrat-Regular";
	            }

	            a[type="submit"] {
					border-radius: 48px;
					border: 2px solid #1c3254;
					text-align: center;
	            }

				 .MuiInputBase-root,
				 .MuiFormControl-root,
	             > div {
	               width:100%;
	            }

	            .textBlock {
	                span {
	                    top: -10px;
                            left: 13px;
                            position: absolute;
                            background: #fff;
                            color: #666666;
                            display: block;
                            z-index: 100;
                            font-family: "Montserrat-Regular";
	                }
		            > div {
		                margin-top: calc($space * 4);
		                position:relative;
			             > div {
			                border: 1px solid #c4c4c4;
		                    border-radius: 5px;
		                    font-family: "Roboto","Helvetica","Arial",sans-serif;
		                    padding: 12px;
	                        color: #1c3254;
	                        font-size: 12px;
                            font-family: "Montserrat-Regular";
			            }
		            }
	            }


	            i {
                    font-style: normal;
                    text-decoration: none;
	                border-color:#1c3254;
	                color:#1c3254;
                    border-radius: 48px;
                    border: 2px solid #1c3254;
                    text-align: center;
                    display:blocK;
                    cursor:pointer;
	            }

	            .delete {
	                border-color:red !important;
	                color:red;
	            }

	         }

			.map-container {
	            width:100%;
	            height: calc(100vh - 90px);
	         }




        .polygonSettings {
			width: 40%;
			min-width: 660px;
            position: absolute;
            bottom: 0;
            background: #ffffff;
            left: calc(50% - 20%);
            padding: calc($space * 4);
            border-top-left-radius:5px;
            border-top-right-radius:5px;
            display:none;
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, .2);

            .polygonSettingsContent {
                position:relative;

                button {
                    position:absolute;
                    right:- calc($space * 5);
                    top:- calc($space * 5);
                    border:0;
                    font-size:30px;
                    height:40px;
                    width:40px;
                    border-radius:20px;
                    background: #ffffff;
                    cursor:pointer;
                }
            }
			.polygonSettingsContent {
				a {

                    border:0;
                    display:block;
                    padding:16px 0;
                    font-size:calc($fontSize * 1);
                    font-weight:600;
                    border-radius:5px;
                    color:#1c3254;
                    border:2px solid #1c3254;
                    text-align:center;
                    cursor:pointer;
                }

                label {
                    background:#fff;
                }
                a:hover{

                    opacity:0.8;
                }

            }

            .polygonSettingsContentPolygon
            {
                display:grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: calc($space * 2);
                  row-gap: calc($space * 2);
            }

            .polygonSettingsContentPoint{
                display:grid;
                grid-template-columns: 70% 30%;
                column-gap: calc($space * 2);
                  row-gap: calc($space * 2);
            }
        }
        .polygonSettings[data-show="true"] {
			display:block;
        }
        .polygonSettings[data-show="false"] {
			display:none;
        }
    }

 /* page-project-profile */

	#page-project-draft-details {

		.box {
			padding: 30px;
			box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
			border-radius: 6px;
			margin-bottom: 36px;
		}


        .OnOfficeList,
		.flowfactList {

			>div {
				display:flex;
				justify-content:space-between;
				margin-bottom: calc($space * 2);
			}


			.cardName {
			    h5 {
			        padding-bottom: $space ;
			        width: 100%;
			        white-space: nowrap;
			        overflow: hidden;
			        text-overflow: ellipsis;
			    }
			}

			.cardAction {

			    justify-content:space-around;
			    a {
			        padding: 6px 24px 0 0;
			        font-weight: 600;
			        margin:auto;
			        display:inline-block;

			    }

			    div {
			        display: flex;
			        a:first-child {
			            margin-bottom: calc($space * 2);
			        }
			    }



			    a.noBorder {
			        border: 0;
			    }

			    svg {
			        font-size:1.1rem;
			    }


			}

			.delete {
                padding: 5px 8px 4px 8px;
                border: 2px solid #d55252;
                border-radius: 50px;
                color: #d55252;
                margin-top: 4px;
                display: block;
                height: 36px;
                font-size: 1.1rem;
                cursor:pointer;
            }

			.card {
			    width:100%;
			    border-radius:5px;
			    margin-bottom: calc($space * 3) ;

			}


			.flowfactCardDetails {
			    display: flex;
			    flex-wrap:wrap;
			    div {
			        display: flex;
			        align-items: center;
			        justify-content: space-between;
			        padding: 0 calc($space * 2) calc($space * 2) 0;
			    }
			    svg {
			        font-size: 14px;
			        margin-right:$space;
			    }
			}
		}


	}





 /* page-project-profile */

	#page-project-profile {
		padding:0 calc($space * 4);

		.notFound {
			padding: 0  0 calc($space * 5) 0;

		}

		.map-container {
			height: 250px;
            border-radius: 5px;
            border:5px solid #fff;
		}

		.projectFlowTitle {
			padding: calc($space * 10)  calc($space * 5) calc($space * 5) calc($space * 5) ;
			display:flex;
			justify-content:space-between;

			a {
			    padding: 6px 12px;
                border-radius: 50px;
                border: 2px solid #1c3254;
                font-weight: 600;
                margin-bottom: -10px;
                display: inline-block;
			}

			.green {
                color:#8bc34a;
            }
            .yellow {
                color:#ff9800;
            }
            .grey {
                color:#ccc;
            }

            svg {
                margin-right: calc($space * 1)
            }

            span {
                display: block;
				font-size: 12px;
                text-align: left;
            }

			h3 {
			    width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height:150%;
			}

		}

		.tox  {
			border-radius:5px;
		}

		.tox-statusbar {
			display:none;
		}

		.cardContainer {
			margin-bottom: calc($space * 3);

			.cardName {
				h5 {
	                padding-bottom: $space ;
	                width: 100%;
	                white-space: nowrap;
	                overflow: hidden;
	                text-overflow: ellipsis;
	            }

                svg {
                  font-size:25px;
                  position:absolute;
                  margin-left:-24px;
                  margin-top:-2px;
                  color:#ddd;
                }
			}

			.cardAction {

				justify-content:space-around;
				a {
					padding: 6px 24px 0 0;
					font-weight: 600;
					margin:auto;
					display:inline-block;

			    }

			    div {
			        display: flex;
                    a:first-child {
                        margin-bottom: calc($space * 2);
                    }
			    }



			    a.noBorder {
			        border: 0;
			    }

				svg {
					font-size:1.1rem;
				}

			    .delete {
		            padding: 5px 8px 4px 8px;
                    border: 2px solid #d55252;
                    border-radius: 50px;
                    color: #d55252;
                    margin-top: 4px;
                    display: block;
                    height: 36px;
			    }
			}

			.card {
				width:100%;
				border-radius:5px;
				margin-bottom: calc($space * 3) ;

			}


			.cardDetails {
				display: flex;
				flex-wrap:wrap;
				div {
					display: flex;
                    align-items: center;
	                justify-content: space-between;
	                padding: 0 calc($space * 2) calc($space * 2) 0;
	            }
	            svg {
                    font-size: 14px;
					margin-right:$space;
                }
            }
		}

		.draftList {
			display:flex;

			a {
				width:100%;
			}

			.delete {
				    padding: 5px 8px 4px 8px;
                    border: 2px solid #d55252;
                    border-radius: 50px;
                    color: #d55252;
                    margin-top: 4px;
                    display: block;
                    height: 36px;
                    font-size: 1.1rem;
			}
		}

		.projectFlowElement {
			padding: calc($space * 5)  ;
			box-shadow: 0 0 24px 0 rgba(0, 0, 0, .1);
			border-radius:$space;
			margin-bottom: calc($space * 3);

			.summary {
                margin-top:10px;
            }

			h3 {
				margin-bottom: calc($space * 4);
				display:flex;
				justify-content:space-between;

				span {
				    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
				}
				a {
                    border: 2px solid #1c3254;
                    padding: 6px 18px;
                    border-radius: 50px;
                    height:35px;
                    font-size: 12px;
                    right: 12px;
                    margin-left:10px;
                }

				svg {
					margin-left: - calc($space * 4.8);
					font-size:1.3rem;
					margin-toP:3px;
					position:absolute;
					background:#fff;

				}
			}

			.projectFlowElementConfigurator {
				padding-left: calc($space * 3);
                margin-left:  calc($space * 3);
				border-left: 2px dashed #ddd;

				.processElement {

					small {
						font-size:12px;
						color:#54b491;
					}
				}

				> div {
					margin-bottom: calc($space * 8)
				}

				p {
					margin-top:0;
				}

			}


			.projectFlowElementTitle {
	            display: flex;
	            margin-bottom: calc($space * 5) ;
				align-items:center;


	            div {
	                line-height:1.56;
	                white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    h5 {
                        width:100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

					span {
					    padding: $space  calc($space * 2);
	                    border-radius: 50px;
	                    font-weight: 600;
	                    margin-bottom:-10px;
	                    display:inline-block;

	                    svg {
	                        margin-bottom:-6px;
	                        position:relative;
	                        margin-right:$space;
	                        color:#1c3254;
	                    }
					}


	                p {
	                    margin:0;
	                    font-size: calc($fontSize * .9);
	                }
	            }

				 div:last-child {
				    width: 100%;

					a {
                        display:flex;
                        justify-content:space-between;
				    }


				 }

				svg {
					color: #1c3254;
                    position: absolute;
                    font-size: 20px;
                    background: #fff;
                    border-radius: 50px;
				}

	            img {
	                width: 45px;
                    margin-right: calc($space * 4);
                    padding: 5px;
                    border: 2px solid #bbc1c9;
                    border-radius: 50%;
	            }
	            .progress {
	                padding-right:calc($space * 2);
	            }
	        }



	        .projectFlowElementTitle.Highlighted  {
                div {
                    img,
                    h5{
                        opacity:1;
                    }
                }
            }

	        .projectFlowElementBlock {


				> div {
					padding-bottom: calc($space * 5);
				}

				> div:last-child {
                    padding-bottom: 0;
                }

                .projectFlowElementBlockSpacer{
                    padding:8px;
                    width:20px !important;
                    display:block;
                    border:2px solid #BBC1C9;
                    border-radius:50px;
                    margin-left:14.5px;
                    margin-right: calc($space * 5 + 6px);

                    svg {
	                    margin-left: -10px;
	                    margin-top: -10px;
	                }
                }
	        }

	        .projectFlowElementDetail {
	            font-family: 'Montserrat-medium';
	            font-size: calc($fontSize * 0.9);
	            color: #BBC1C9;
	        }
		}

		.projectFlowElement .projectFlowElementBlock:last-child {
			padding-bottom:0;
		}

		.map {
			border: 2px solid #eee;
			border-radius: 34px;
			height:200px;
		}


		.accessListElement {
			display:flex;
			padding: calc($space * 2) !important;
			align-items: center;
			justify-content:space-between;

			>div:first-child {
				width:30%;
				min-widht:150px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-right: calc($space * 2);
			}



			.delete {
				font-size:1.1rem;
			}

			.token {
				width:40%;
                min-widht:60px;
                justify-content:space-between;
                white-space: nowrap;
                overflow: hidden;
                	align-items: center;
                display:flex;
                text-overflow: ellipsis;
                margin:0 calc($space * 2);
                border:1px solid #ccc;
                padding: calc($space * 1.1) calc($space * 2);
                border-radius:50px;

                a {
                    font-size: 1.1rem;
                    cursor:pointer;
                }

                > div {
                    width: calc(100% - 80px);
                    margin: auto;
                    overflow: hidden;
                }
			}
		}
	}


 .myDocuments {
   width:100%;


   .divide-y {
     padding:0;
     padding-right: 24px;

     li {
       padding: 10px 0;
     }
   }

   button {
     background: #fff;
     border:0;
     font-size: calc($fontSize * 1.25);
     cursor: pointer;

   }
   button:hover {
     color:red !important;
   }

   .text-sm {
     font-size: $fontSize;
     font-weight: normal;
   }

   .w-full {
     width: 100%;

     b {
       display:block;
       margin-bottom:20px;
       font-size: calc($fontSize * 1.25);
     }
   }
   .flex  {
     display:flex;
     justify-content: space-between;
   }

   .mt-2 {
     border:2px dashed #aaa;
     border-radius: 10px;
     padding:40px;

     svg {
       font-size: 40px;
     }

     .text-center {
       text-align: center;
       align-self: center;
     }
   }
 }




 @media only screen and (max-width: 1400px) {

	#page-project-overview .plotList  {
       grid-template-columns: 32% 32% 32% ;
    }
}







@media only screen and (max-width: 800px) {

	#page-project-overview .plotList  {
       grid-template-columns: 49% 49%;
    }

    //page-project-profile
     #page-project-profile  {
		.cardContainer {
	        grid-template-columns: 48% 48%;
	        grid-gap: 2%;
	    }
    }

    //page-project-draft
    #page-drafts-map > div {
        display:block;
    }

	#page-drafts-map > div .data-container {
		border: 0px;
        height: auto;
        width: 100%;
	}

	#page-drafts-map > div .map-container {
        width: calc(100vw);
        height: calc(50vh);
    }

	#page-drafts-map .polygonSettings {
		position:relative;
		left:0;
		width: 100%;
        min-width: auto;
		box-sizing:border-box;
		.polygonSettingsContentPolygon {
			grid-template-columns: 1fr;
		}
	}

}


@media only screen and (max-width: 500px) {

	#page-project-overview .plotList  {
       grid-template-columns: 98%;
	}

    //page-project-profile
	#page-project-profile  {
	    .cardContainer {
	        grid-template-columns: 1fr;
	    }
	}

}


 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }

 .spin-animation {
   animation: spin infinite 1s linear; /* Apply the animation to the element */
 }